// TODO : ajouter une photo de moi ?

import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import TechList from "../components/tech";
import Cv from "../components/cv";
import Homebtn from "../components/home";

export default function About() {
  const query = graphql`
    {
      contentfulAbout {
        meDescription
        schoolDescription
        title
        skillTitle
      }
    }
  `;
  const data = useStaticQuery(query);
  const about = data.contentfulAbout;

  return (
    <div className="max-width">
      <div className="top-bottom-margin">
        <div className="max-width-70 text-white animate delay02">
          <Homebtn></Homebtn>

          <h3 className="text-white">{about.title}</h3>

          <h5>{about.schoolDescription}</h5>

          <p className="align-text">{about.meDescription}</p>

          <h5>{about.skillTitle}</h5>

          <ul className="tech-list">
            <TechList></TechList>
          </ul>

          <Cv></Cv>
        </div>
      </div>
    </div>
  );
}
